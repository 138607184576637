/**
 * Error Handler per chiamate ajax
 *
 * @type {{init: errorHandler.init}}
 */
var errorHandler = {

    init: function () {
        if (typeof T == 'undefined')
            T = {};

        if (typeof T.serverError == 'undefined')
            T.serverError = 'Errore server';

        if (typeof T.serverMaintenance == 'undefined')
            T.serverMaintenance = 'Server in manutenzione, riprova più tardi';

        $(document).ajaxComplete(function myErrorHandler(event, xhr, ajaxOptions, thrownError) {
            if (typeof errorHandler[xhr.status] == 'function') {
                errorHandler["" + xhr.status](event, xhr, ajaxOptions, thrownError);
            } else {
                if (xhr.status != 200 && xhr.status != 0) {
                    // check loggedout
                    if (xhr.status == 401 || xhr.status == 419) {
                        $.get(app.baseUrl)
                            .done(function (data) {
                                app.warning("", T.serverError + ": " + xhr.status);
                            })
                            .fail(function (data) {
                                app.href('login', 0);
                            });
                    } else if (xhr.status == 422) {
                        if (app.debug)
                            app.warning("", "[DEBUG] " + T.serverError + ": " + xhr.status);
                    } else if (xhr.status == 503) {
                        if (app.debug)
                            app.warning("", "[DEBUG] " + T.serverMaintenance + ": " + xhr.status);
                    } else if (!app.suppressWarning) {
                        app.warning("", T.serverError + ": " + xhr.status);
                    }
                }
            }
        });
    }
};